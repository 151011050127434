<template>
  <div class="q-pa-md">
    <div aria-label="АП агрегированные данные" class="l-table" role="table">
      <div class="l-table__row" role="rowgroup">
        <div
          v-for="column in columns"
          :key="column.name"
          :style="column.style"
          class="l-table__cell"
          role="columnheader"
        >
          <div class="text-body3 text-grey-6">{{ column.label }}</div>
        </div>
      </div>

      <div
        v-for="apBox in apBoxBlend.ap_boxes"
        :key="apBox.id"
        class="l-table__row"
        role="rowgroup"
      >
        <div :style="getColumnStyle('serial')" class="l-table__cell">
          {{ apBox.serial }}
        </div>

        <div
          :style="getColumnStyle('name')"
          :title="apBox.name"
          class="l-table__cell"
        >
          {{ shortness(apBox.name, 80) }}
        </div>

        <div :style="getColumnStyle('builder')" class="l-table__cell">
          {{ apBox.builder }}
        </div>

        <div :style="getColumnStyle('developer')" class="l-table__cell">
          {{ apBox.developer }}
        </div>

        <div :style="getColumnStyle('sales_realised')" class="l-table__cell">
          {{ apBox.sales_realised }}
        </div>

        <div :style="getColumnStyle('floor_max')" class="l-table__cell">
          {{ apBox.floor_max }}
        </div>

        <div :style="getColumnStyle('flat_qty')" class="l-table__cell">
          {{ apBox.flat_qty }}
        </div>

        <div :style="getColumnStyle('parking_qty')" class="l-table__cell">
          {{ apBox.parking_qty }}
        </div>

        <div :style="getColumnStyle('date_rve')" class="l-table__cell">
          {{ apBox.date_rve }}
        </div>

        <div :style="getColumnStyle('ceiling_height')" class="l-table__cell">
          {{ getCeilingHeight(apBox.ceiling_height) }}
        </div>

        <div :style="getColumnStyle('finishing_type')" class="l-table__cell">
          {{ apBox.finishing_type }}
        </div>

        <div :style="getColumnStyle('living_square')" class="l-table__cell">
          {{ apBox.living_square }}
        </div>

        <div :style="getColumnStyle('cadastrals')" class="l-table__cell">
          {{ apBox.cadastrals && apBox.cadastrals.join(", ") }}
        </div>

        <div :style="getColumnStyle('actions')" class="l-table__cell">
          <div class="flex no-wrap">
            <q-btn
              :href="getApBoxLink(apBox.serial)"
              alt="Перейти на страницу корпуса"
              flat
              icon="mdi-open-in-new"
              target="_blank"
              type="a"
            />
          </div>
        </div>
      </div>

      <div
        class="l-table__row"
        role="rowgroup"
        style="background-color: #c1d6ee; border-bottom: 5px solid #acbed1"
      >
        <div :style="getColumnStyle('serial')" class="l-table__cell" />
        <div :style="getColumnStyle('name')" class="l-table__cell" />

        <div :style="getColumnStyle('builder')" class="l-table__cell">
          {{ apBoxBlend.builder && apBoxBlend.builder.join(", ") }}
        </div>

        <div :style="getColumnStyle('developer')" class="l-table__cell">
          {{ apBoxBlend.developer && apBoxBlend.developer.join(", ") }}
        </div>

        <div :style="getColumnStyle('sales_realised')" class="l-table__cell">
          {{ apBoxBlend.sales_realised }}
        </div>

        <div :style="getColumnStyle('floor_max')" class="l-table__cell">
          {{ apBoxBlend.floor_max }}
        </div>

        <div :style="getColumnStyle('flat_qty')" class="l-table__cell">
          {{ apBoxBlend.flat_qty }}
        </div>

        <div :style="getColumnStyle('parking_qty')" class="l-table__cell">
          {{ apBoxBlend.parking_qty }}
        </div>

        <div :style="getColumnStyle('date_rve')" class="l-table__cell">
          {{ apBoxBlend.date_rve && apBoxBlend.date_rve.join(", ") }}
        </div>

        <div
          :style="getColumnStyle('ceiling_height')"
          class="l-table__cell pre-line"
        >
          {{ getCeilingHeight(apBoxBlend.ceiling_height) }}
        </div>

        <div :style="getColumnStyle('finishing_type')" class="l-table__cell">
          {{
            apBoxBlend.finishing_type && apBoxBlend.finishing_type.join(", ")
          }}
        </div>

        <div :style="getColumnStyle('living_square')" class="l-table__cell">
          {{ apBoxBlend.living_square }}
        </div>

        <div :style="getColumnStyle('cadastrals')" class="l-table__cell">
          {{ apBoxBlend.cadastrals && apBoxBlend.cadastrals.join(", ") }}
        </div>

        <div :style="getColumnStyle('actions')" class="l-table__cell" />
      </div>
    </div>

    <div
      aria-label="ДомРФ агрегированные данные"
      class="l-table q-mt-md"
      role="table"
    >
      <div class="l-table__row" role="rowgroup">
        <div
          v-for="column in columns"
          :key="column.name"
          :style="column.style"
          class="l-table__cell"
          role="columnheader"
        >
          <div class="text-body3 text-grey-6">{{ column.label }}</div>
        </div>
      </div>

      <div
        v-for="box in boxBlend.boxes"
        :key="box.id"
        class="l-table__row"
        role="rowgroup"
      >
        <div :style="getColumnStyle('serial')" class="l-table__cell">
          {{ box.serial }}
        </div>

        <div
          :style="getColumnStyle('name')"
          :title="box.name"
          class="l-table__cell"
        >
          {{ shortness(box.address || box.name, 80) }}
        </div>

        <div :style="getColumnStyle('builder')" class="l-table__cell">
          {{ box.builder }}
        </div>

        <div :style="getColumnStyle('developer')" class="l-table__cell">
          {{ box.developer }}
        </div>

        <div :style="getColumnStyle('sales_realised')" class="l-table__cell">
          {{ box.sales_realised }}
        </div>

        <div :style="getColumnStyle('floor_max')" class="l-table__cell">
          {{ box.floor_max }}
        </div>

        <div :style="getColumnStyle('flat_qty')" class="l-table__cell">
          {{ box.flat_qty }}
        </div>

        <div :style="getColumnStyle('parking_qty')" class="l-table__cell">
          {{ box.parking_qty }}
        </div>

        <div :style="getColumnStyle('date_rve')" class="l-table__cell">
          {{ box.date_rve }}
        </div>

        <div :style="getColumnStyle('ceiling_height')" class="l-table__cell">
          {{ getCeilingHeight(box.ceiling_height) }}
        </div>

        <div :style="getColumnStyle('finishing_type')" class="l-table__cell">
          {{ box.finishing_type }}
        </div>

        <div :style="getColumnStyle('living_square')" class="l-table__cell">
          {{ box.living_square }}
        </div>

        <div :style="getColumnStyle('cadastrals')" class="l-table__cell">
          {{ box.cadastrals && box.cadastrals.join(", ") }}
        </div>

        <div :style="getColumnStyle('actions')" class="l-table__cell">
          <div class="flex no-wrap">
            <q-btn
              :href="getBoxLink(box.serial)"
              flat
              icon="mdi-open-in-new"
              target="_blank"
              title="Перейти на страницу корпуса"
              type="a"
            />
            <q-btn
              v-if="box.pd_files && box.pd_files.length"
              :href="box.pd_files[0].external_url_inline"
              flat
              icon="mdi-file-document-outline"
              target="_blank"
              title="Открыть ПД"
              type="a"
            />
          </div>
        </div>
      </div>

      <div
        class="l-table__row"
        role="rowgroup"
        style="background-color: #deedbc; border-bottom: 5px solid #c4d1a6"
      >
        <div :style="getColumnStyle('serial')" class="l-table__cell" />
        <div :style="getColumnStyle('name')" class="l-table__cell" />

        <div :style="getColumnStyle('builder')" class="l-table__cell">
          {{ boxBlend.builder && boxBlend.builder.join(", ") }}
        </div>

        <div :style="getColumnStyle('developer')" class="l-table__cell">
          {{ boxBlend.developer && boxBlend.developer.join(", ") }}
        </div>

        <div :style="getColumnStyle('sales_realised')" class="l-table__cell">
          {{ boxBlend.sales_realised }}
        </div>

        <div :style="getColumnStyle('floor_max')" class="l-table__cell">
          {{ boxBlend.floor_max }}
        </div>

        <div :style="getColumnStyle('flat_qty')" class="l-table__cell">
          {{ boxBlend.flat_qty }}
        </div>

        <div :style="getColumnStyle('parking_qty')" class="l-table__cell">
          {{ boxBlend.parking_qty }}
        </div>

        <div :style="getColumnStyle('date_rve')" class="l-table__cell">
          {{ boxBlend.date_rve && boxBlend.date_rve.join(", ") }}
        </div>

        <div
          :style="getColumnStyle('ceiling_height')"
          class="l-table__cell pre-line"
        >
          {{ getCeilingHeight(boxBlend.ceiling_height) }}
        </div>

        <div :style="getColumnStyle('finishing_type')" class="l-table__cell">
          {{ boxBlend.finishing_type && boxBlend.finishing_type.join(", ") }}
        </div>

        <div :style="getColumnStyle('living_square')" class="l-table__cell">
          {{ boxBlend.living_square }}
        </div>

        <div :style="getColumnStyle('cadastrals')" class="l-table__cell">
          {{ boxBlend.cadastrals && boxBlend.cadastrals.join(", ") }}
        </div>

        <div :style="getColumnStyle('actions')" class="l-table__cell" />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    formatCeilingHeight,
    formatCeilingHeightArray,
    getApBuildingLink,
    getDomRfBuildingLink,
  } from "@/utils/batch";
  import { shortness } from "@/plugins/filters";

  export default {
    name: "ExpandedCompareRow",

    props: {
      boxBlend: {
        type: Object,
        required: true,
      },
      apBoxBlend: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        columns: [
          {
            name: "serial",
            label: "Номер",
            style: "width: 265px;",
          },
          {
            name: "name",
            label: "Адрес/Название",
            style: "width: 160px;",
          },
          {
            name: "builder",
            label: "Застройщик",
            style: "width: 200px;",
          },
          {
            name: "developer",
            label: "Девелопер",
            style: "width: 200px;",
          },
          {
            name: "sales_realised",
            label: "Сделки",
            style: "width: 100px;",
          },
          {
            name: "floor_max",
            label: "Этажность",
            style: "width: 100px;",
          },
          {
            name: "flat_qty",
            label: "Квартир",
            style: "width: 100px;",
          },
          {
            name: "parking_qty",
            label: "Машиномест",
            style: "width: 110px;",
          },
          {
            name: "date_rve",
            label: "РВЭ",
            style: "width: 160px;",
          },
          {
            name: "ceiling_height",
            label: "Высота потолков",
            style: "width: 120px;",
          },
          {
            name: "finishing_type",
            label: "Отделка",
            style: "width: 160px;",
          },
          {
            name: "living_square",
            label: "Жил. площадь",
            style: "width: 110px;",
          },
          {
            name: "cadastrals",
            label: "Кадастр",
            style: "width: 160px;",
          },
          {
            name: "actions",
            label: "",
            style: "width: 120px;",
          },
        ],
      };
    },

    methods: {
      shortness(value, length) {
        return shortness(value, length);
      },

      getColumnStyle(col) {
        return this.columns.filter((obj) => obj.name === col)[0].style;
      },

      getApBoxLink(serial) {
        return getApBuildingLink(serial);
      },

      getBoxLink(serial) {
        return getDomRfBuildingLink(serial);
      },

      getCeilingHeight(value) {
        return formatCeilingHeight(value);
      },
    },
  };
</script>
